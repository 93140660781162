export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"format-detection","content":"telephone=no"},{"name":"theme-color","content":"#000000"}],"link":[{"rel":"icon","type":"image/png","href":"/images/favicon.png"},{"rel":"apple-touch-icon","href":"/images/icon-120x120.png"},{"rel":"manifest","href":"/manifest.json"},{"rel":"preconnect","href":"https://www.datocms-assets.com"}],"style":[],"script":[{"hid":"gtm-init","innerHTML":"\n\n          // Define functions for gtag and gtm\n          // Not working with useHead() !\n\n          window.dataLayer = window.dataLayer || [];\n          function gtag(){dataLayer.push(arguments);}\n          \n          // Initialize gtag with default consent\n          gtag('consent', 'default', {\n            'ad_storage': 'denied',\n            'ad_user_data': 'denied',\n            'ad_personalization': 'denied',\n            'analytics_storage': 'denied'\n          });\n          ","type":"text/javascript"}],"noscript":[],"titleTemplate":"%s"}

export const appPageTransition = {"name":"page","mode":"out-in"}

export const appCdnUrl = "/netlify/66f263cff5a5a50008f65cc2--sti-web.netlify.app"

export const appBaseUrl = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appLayoutTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'